<app-socials></app-socials>
<div  class="chtl-hamburger-menu">
    <img (click)="toggleMenu()" src="assets/images/menu.svg" alt="">
</div>
<div class="chtl-navigation" #mm>
    <div class="chtl-nav" *ngFor="let nav of navigations">
        <a class='chtl-link' [routerLink]="nav.url" [routerLinkActive]="'chtl-active'" [href]="nav.url">
            {{nav.name}}
        </a>
    </div>
</div>
