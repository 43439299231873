import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from '../app.component';
import { BchApplicationComponent } from '../bch-application/bch-application.component';
import { HomeComponent } from '../home/home.component';
import { PaqComponent } from '../paq/paq.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'application', component: BchApplicationComponent },
  { path: '#conditions', component: HomeComponent },
  { path: 'faq', component: PaqComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BchlRoutingModule { }
