<div class="chtl-footer">
    <div class="chtl-socials">
        <span> Retrouvez-nous sur  </span> <app-socials></app-socials> 
    </div>
    <div class="chtl-legal">
      <a style="color: white;" href="https://www.cosumar.co.ma/"> <span>Copyright ©2021 COSUMAR</span></a> 
        <div class="chtl-legal-popup">
            <div (click)="openModal=true">Mentions légales</div>
        </div>
    </div>
</div>

<clr-modal [clrModalSize]="modalSize" [(clrModalOpen)]="openModal">
    <h3 class="modal-title">المعطيات القانونية</h3>
    <div class="modal-body">
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>المعطيات القانونية&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>أنتم الآن تستعملون الموقع الإلكتروني &nbsp;</span><span style="color:#0563C1;text-decoration:underline;"><a href="http://www.minhatafawoq.cosumar.ma/"><span dir="LTR">www.minhatafawoq.cosumar.ma</span></a></span><span style='font-family:"Arial",sans-serif;'>&nbsp;الخاص بشركة كوسومار&nbsp;</span><span dir="LTR">21</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span dir="LTR">COSUMAR S.A</span><span style='font-family:"Arial",sans-serif;'>., &copy;&nbsp;</span><span dir="LTR">COSUMAR S.A., 20</span><span style='font-family:"Arial",sans-serif;'>&nbsp;&ndash; جميع الحقوق محفوظة.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>التعريف &nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>صاحب الموقع:&nbsp;</span><span dir="LTR">COSUMAR S.A&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>شركة مجهولة الاسم&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>الرأسمال الاجتماعي:&nbsp;</span><span dir="LTR">419</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span dir="LTR">105</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span dir="LTR">700</span><span style='font-family:"Arial",sans-serif;'>&nbsp;درهم&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>المقر الاجتماعي:&nbsp;</span><span dir="LTR">8</span><span style='font-family:"Arial",sans-serif;'>&nbsp;زنقة المعتمد ابن عباد، صندوق البريد :</span><span dir="LTR">3098</span><span style='font-family:"Arial",sans-serif;'>&nbsp;الصخور السوداء، الدار البيضاء، المغرب&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>الهاتف:&nbsp;</span><span dir="LTR">83</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span dir="LTR">02</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span dir="LTR">529</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span dir="LTR">00212</span><span style='font-family:"Arial",sans-serif;'>&nbsp;الفاكس:&nbsp;</span><span dir="LTR">71</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span dir="LTR">10</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span dir="LTR">24</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span dir="LTR">522</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span dir="LTR">00212</span><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>عنوان الموقع:&nbsp;</span><span style="color:#0563C1;text-decoration:underline;"><a href="http://www.minhatafawoq.cosumar.ma/"><span dir="LTR">http://www.minhatafawoq.cosumar.ma</span></a></span><span dir="LTR">&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>رقم السجل التجاري:&nbsp;</span><span dir="LTR">30037</span><span style='font-family:"Arial",sans-serif;'>&nbsp;الدار البيضاء.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>مضيف الموقع: إنوي&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>للتعبير عن الملاحظات الخاصة باشتغال الموقع، المرجو بعث رسالة إلكترونية إلى البريد الخاص بالمسؤول عن الموقع :&nbsp;</span><span style="color:#0563C1;text-decoration:underline;"><a href="mailto:h.hadri@cosumar.co.ma"><span dir="LTR">h.hadri@cosumar.co.ma</span></a></span><span dir="LTR">&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>حقوق الطبع والنشر والعلامات التجارية&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>إن هذا الموقع، محتوى وشكلا، يشكل &nbsp; عملا محميا من طرف التشريعات المغربية والدولية الجاري بها العمل في إطار حقوق المؤلف و الملكية الفكرية و الصناعية.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>جميع حقوق الملكية الفكرية والصناعية (حقوق المؤلف، براءات الاختراع، العلامات التجارية)، بما تشمله من حقوق الاستغلال وحقوق الاستنساخ و الاقتباس و حقوق العرض و حقوق التعديل و الترجمة لجميع أو بعض العناصر المتضمنة في صفحات هذا الموقع ، و تعود بشكل حصري إلى شركة كوسومار (</span><span dir="LTR">copyright</span><span style='font-family:"Arial",sans-serif;'>&nbsp;&copy;&nbsp;</span><span dir="LTR">COSUMAR S.A</span><span style='font-family:"Arial",sans-serif;'>.) و مانحي التراخيص المتعاملين معها فيما يتعلق بالوثائق القابلة للتحميل والرسومات البيانية و الإيكونوغرافية والصور الفوتوغرافية و الرقمية و السمعية البصرية، وذلك في ظل احترام حقوق هذه الأطراف التي ترخص لشركة كوسومار استنساخ أو إدراج أعمالهم &nbsp;على الموقع الإلكتروني.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>يمنع قانونيا الاستنساخ الجزئي أو الكلي للعناصر السالفة الذكر بصفة عامة أو الاستنساخ الجزئي أو الكلي لهذا الموقع على أي حامل إلكتروني كيفما كان نوعه منعا كليا بدون ترخيص خطي مسبق من طرف شركة كوسومار.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>يظل استنساخ النصوص و الصفحات المدرجة في الموقع على حامل ورقي مسموحا به في ظل احترام الشروط &nbsp;التالية مجتمعة: مجانية النشر، احترام وحدة الوثيقة المستنسخة والغاية منها (دون تعديل أو تغيير)، احترام الغاية من الموقع فيما يتعلق باستعمال المعلومات والعناصر المتضمنة فيه، الإشارة بوضوح إلى المصدر مع ذكر اسم الموقع وعنوانه على الإنترنيت وحقوق النشر المحفوظة لكوسومار.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>من ناحية ثانية، تعتبر جميع العلامات التجارية المذكورة أو المبينة في هذا الموقع ملكية خاصة بأصحابها.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>الشروط العامة لاستعمال الصور&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>يمكن للصور المبينة في موقع كوسومار أن تكون محملة بحق الملكية الأدبية والفنية، أو حق الملكية الصناعية، أو حق المجاورة، أو حق الشخصية، أو الحق المعنوي، أو &nbsp;حق الملكية أو أي حق من الحقوق الأخرى التي يتمتع بها طرف ثالث.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>يمنع منعا كليا استنساخ أو تبيان أو اقتباس أو تعديل جزئي أو كلي للصور المبينة في الموقع الإلكتروني، لغاية النشر العمومي (الاستعمالات &ldquo;البيداغوجية&rdquo; و &ldquo;المواقع الشخصية) أو لغاية تجارية كيفما كانت الوسيلة أو الطريقة المتبعة.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>آثار مستخدمي الإنترنيت&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>شريط &ldquo;الكوكيز&rdquo;&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>أثناء مواصلتكم تصفح هذا الموقع، فإنكم توافقون على استعمال الكوكيز أو أي متتبع آخر لآثار المستخدمين من أجل اقتراح إشهارات تتناسب مع اهتماماتكم وكذلك من أجل إنجاز الإحصائيات المتعلقة بزيارات الموقع.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>إن شركة كوسومار تتعهد بعدم استخدام كوكيز الإنترنيت (ملفات صغيرة ترسل إلى القرص الصلب لحاسوبكم و تدل على الربط بالإنترنيت) و ملفات &nbsp;ذاكرة التخزين المؤقتة وملفات متغيرات البيئة وملفات الافتحاص لأغراض غير تحليل و تحسين أداء هذا الموقع.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>يمكنكم رفض تسجيل الكوكيز من خلال ضبط إعدادات المتصفح المثبت بنظام حاسوبكم من أجل إشعاركم بوصول الكوكيز حتى يتسنى لكم رفضه.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>المعلومات المجمعة عبر استمارة الترشيح &nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>صرحت شركة كوسومار بالمعالجة الآلية للمعلومات الشخصية، تحت رقم&nbsp;</span><span dir="LTR">CNDP xxx</span><span style='font-family:"Arial",sans-serif;'>&nbsp;للجنة الوطنية لمراقبة حماية المعطيات ذات الطابع الشخصي، طبقا للقانون رقم&nbsp;</span><span dir="LTR">08</span><span style='font-family:"Arial",sans-serif;'>-</span><span dir="LTR">09</span><span style='font-family:"Arial",sans-serif;'>&nbsp;بتاريخ&nbsp;</span><span dir="LTR">18</span><span style='font-family:"Arial",sans-serif;'>&nbsp;فبراير&nbsp;</span><span dir="LTR">2009</span><span style='font-family:"Arial",sans-serif;'>&nbsp;&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>تخضع &nbsp;المعلومات التي يتم جمعها لمعالجة آلية هدفها تصنيف طلبات الترشيح واختيار الفائزين. تتلقى هذه المعلومات من طرف لجنة اختيار يتم تشكيلها أثناء مدة المسابقة. هذه اللجنة تابعة لقسم الاتصالات لشركة كوسومار&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>بموجب القانون المتعلق ب&rdquo;حماية الأشخاص الذاتيين إزاء معالجة المعطيات ذات الطابع الشخصي&rdquo; بتاريخ&nbsp;</span><span dir="LTR">18</span><span style='font-family:"Arial",sans-serif;'>&nbsp;فبراير&nbsp;</span><span dir="LTR">2009</span><span style='font-family:"Arial",sans-serif;'>، تستفيدون من حق ولوج وتعديل المعلومات التي تتعلق بكم، وهو حق يمكنكم ممارسته عبر بعث رسالة إلكترونية لبريد المسابقة :</span><span style="color:#0563C1;text-decoration:underline;"><a href="mailto:minhatafawoq@cosumar.ma"><span dir="LTR">minhatafawoq@cosumar.ma</span></a></span><span style='font-family:"Arial",sans-serif;'>&nbsp;. ويمكنكم أيضا، لأسباب مشروعة، الاعتراض على معالجة المعطيات الخاصة بكم.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>إخلاء لمسؤولية وضمان شركة كوسومار&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>لا يمكن، بأي حال من الأحوال، تحميل كوسومار مسؤولية الأضرار الناتجة بشكل مباشر أو غير مباشر عن حادث أو لامبالاة لا يد لها فيها أو ناتج عن عدم احترام مستعمل الإنترنيت للتوصيات والقيود المنصوص عليها من طرف كوسومار أو كذلك عن استعمال غير ملائم أو سوء فهم من جانب مستعمل الإنترنيت في إطار استخدام هذا الموقع.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>تبرئ كوسومار مسؤوليتها عن أية معلومات محتملة يمكن الحصول عليها من الموقع التي تبدو ماديا خاطئة أو غير تامة (أخطاء تقنية أو اختلالات ناتجة عن النظام المعلوماتي الخاص بالموقع، أخطاء مادية أو تيبوغرافية عند إدخالها في النظام، خطأ أو نسيان أثناء التحديث).&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>تحديد الصلاحيات&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>يخضع هذا الموقع للقانون المغربي. فبموجب اتفاق صريح، منحت الصلاحية عند التقاضي في حالة عدم التوصل إلى اتفاق ودي بين الأطراف، حصريا للمحاكم المختصة بالدار البيضاء التي يعود لها الاختصاص الترابي بغض النظر عن مكان استعمال المنتوج أو أداء الخدمات أو مقر المدعى عليه بما في ذلك الحالات المستعجلة وتعدد المدعى عليهم أو دعوى المطالبة بضمان الحقوق.&nbsp;</span></p>
        <p dir="RTL" style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><span style='font-family:"Arial",sans-serif;'>&nbsp;</span></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="openModal = false">حسنا    </button>
    </div>
  </clr-modal>