import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, AfterViewInit {
  toggled = true;

  @ViewChild('mm')
  mm!: ElementRef;

  navigations = [
    {
      url: '/faq',
      name: 'أسئلة و أجوبة',
    },
    {
      url: '/application',
      name: 'إيداع ملف الترشيح',
    },
    {
      url: '/#conditions',
      name: 'شروط الترشيح',
    },
  ];
  constructor() {}
  ngOnInit(): void {}
  ngAfterViewInit(): void {

  }

  toggleMenu() {
    console.log(12);
    this.toggled = !this.toggled;
    const cc: HTMLDivElement = this.mm.nativeElement;
    cc.classList.add('chtl-expaned');
  }
}
