<div class="section">
  <div class="main">
    <div class="background-image"></div>
    <div class="chtl-header">
      <a href="/#conditions"><img class="app-logo" src="assets/images/applog.png" alt="logo"></a>
      <app-navigation></app-navigation>

    </div>
    <div class="app-text-main">
      <p cds-text class="strong">
        المنح التشجيعية لفائدة أبناء الفلاحين شركاء <strong>كوسومار</strong>
      </p>
      <p cds-text class="light">
        الحاصلين على شهادة البكالوريا بتفوق برسم سنة <strong> 2020 - 2021 </strong> أو سنة <strong> 2021 - 2022
        </strong>
      </p>
    </div>
    <div class="app-video">
      <iframe class="app-video-player" width="500" height="300" [src]="safeURL" title="YouTube video player"
        frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>\
      <br />
      <div class="wrapper">
        <div class="description">
          <p>Jours</p>
          <p>Heures</p>
          <p>Minutes</p>
          <p>Secondes</p>
        </div>
        <div class="times">
          <p id="days">{{days}}</p>
          <p id="hours">{{hours}}</p>
          <p id="mins">{{minutes}}</p>
          <p id="Seconds">{{seconds}}</p>
        </div>
      </div>

    </div>
  </div>
  <div class="circles">
    <div class="circle ci3 small">
      <div class="text c3"> منحة إضافية بقيمة<strong> 5000</strong> درهم للفائزين الحاصلين على ميزة <strong>حسن
          جدا</strong>
      </div>
      <div class="chtl-plus">+</div>
    </div>
    <div class="circle ci1">
      <div class="text c1">الأوائل من أبناء منتجي النباتات السكرية من كل جهة و الحاصلين على شهادة البكالوريا
        بامتياز كتحفيز لمواصلة مسارهم الدراسي</div>
      <div class="number nb1">5</div>
    </div>
    <div class="circle ci2">
      <div class="text c2"> منح <div style="font-weight: bolder;">تشجيعية</div> سنوية
        <p cds-text> بقيمة <strong> 10000 </strong>درهم</p>

      </div>
      <div class="number nb2">5</div>
    </div>
  </div>
  <div class="conditions" id="conditions" style="direction: rtl;">
    <div class="condition">
      <div class="title">شروط الترشيح:</div>
      <div class="points">
        <div class="point"> أن يكون المترشح من أبناء الفلاحين منتجي النباتات السكرية </div>
        <div class="point"> أن يكون اسم الفلاح المنتج مسجلا في لائحة المنتجين للموسم <strong class="chtl-strong-number">
            2020 - 2021 </strong> أو <strong class="chtl-strong-number">
            2021 - 2022 </strong>

        </div>
        <div class="point"> أن تكون شهادة البكالوريا محررة من مؤسسات التعليم العمومي </div>
        <div class="point"> أن لا يتجاوز عمر المترشح <strong class="chtl-strong-number">18</strong> سنة عند حصوله على
          شهادة البكالوريا وفي نفس سنة التسجيل للموسم السكري للأب أو للأم
        </div>
        <div class="point"> أن يكون المترشح حاصلا على شهادة البكالوريا بميزة حسن على الأقل </div>
      </div>
    </div>
    <div class="condition">
      <div class="title">يتضمن ملف الترشيح الوثائق التالية:</div>
      <div class="points">
        <div class="point">نسخة من عقد المنتج</div>
        <div class="point"> نسخة من بيان حصيلة الموسم السكري <strong class="chtl-strong-number">2020 - 2021 </strong> أو
          <strong class="chtl-strong-number">
            2021 - 2022 </strong>

        </div>
        <div class="point">نسخة من شهادة البكالوريا مصادقة عليها مرفقة ببيان نقط النجاح</div>
        <div class="point">نسخة من البطاقة الوطنية للتلميذ</div>
      </div>
    </div>
  </div>
</div>

<div class="footer" style="direction: rtl;">
  <div class="msg">
    <p cds-text>ما عليك سوى اتباع الخطوات الثلاث التالية لتقديم طلبكم </p>
  </div>
  <div class="steps">
    <div class="step">
      <div class="icon">
        <img src="assets/images/user.png" alt="">
      </div>
      <div class="txt"> إدخال رقم المنتج</div>
    </div>
    <div class="arrow">
      <img src="assets/images/yellow-arrow.png" alt="">
    </div>
    <div class="step">
      <div class="icon">
        <img src="assets/images/card.png" alt="">
      </div>
      <div class="txt">إدخال البيانات الشخصية</div>
    </div>
    <div class="arrow">
      <img src="assets/images/yellow-arrow.png" alt="">
    </div>
    <div class="step">
      <div class="icon">
        <img src="assets/images/school.png" alt="">
      </div>
      <div class="txt">إدخال المعطيات الدراسية </div>
    </div>
  </div>

  <div class="chtl-btn">
    <a href="/application">
      <button class="chtl-submit-button">أرسل طلبك للمشاركة</button>
    </a>
  </div>
</div>
<app-footer></app-footer>
