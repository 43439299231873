<div class="section">
    <div class="chtl-header">
        <div class="chtl-header-nav">
            <div class="chtl-logo">
                <a href="/#conditions"><img src="assets/images/applog.png" alt=""></a>
            </div>
            <app-navigation></app-navigation>
        </div>
        <div class="chtl-header-title">
            أسئلة و أجوبة <strong>؟</strong>
        </div>
    </div>
    
    
    <div class="faqs">
        <div class="faq" *ngFor="let faq of faqs">
            <div class="faq-title">{{ faq.title }}</div>
            <div class="faq-content" [innerHTML]="faq.content"> </div>
        </div>
    </div>
    
    
   <div class="chtl-footer">
    <app-footer></app-footer>
   </div>
    
</div>