<div class="chtl-section">
  <div class="chtl-header">
    <div class="chtl-header-nav">
      <div class="chtl-logo">
        <a href="/#conditions"> <img src="assets/images/applog.png" alt=""></a>
      </div>
      <app-navigation></app-navigation>
    </div>
    <div class="chtl-header-title">
      ملف طلب التسجيل
    </div>
  </div>
  <div class="timeline">
    <clr-timeline>
      <clr-timeline-step [clrState]="first_third_state">
        <clr-timeline-step-title>
          <div class="chtl-strong">
            إدخال المعطيات الدراسية
          </div>
        </clr-timeline-step-title>
      </clr-timeline-step>
      <clr-timeline-step [clrState]="first_second_state">
        <clr-timeline-step-title>
          <div class="chtl-strong">
            إدخال البيانات الشخصية
          </div>
        </clr-timeline-step-title>
      </clr-timeline-step>

      <clr-timeline-step [clrState]="first_step_state">
        <clr-timeline-step-title>
          <div class="chtl-strong">
            إدخال رقم بطاقة الفلاح
          </div>
        </clr-timeline-step-title>
      </clr-timeline-step>
    </clr-timeline>
  </div>

  <div class="section">
    <div class="chtl-first-step" *ngIf="step === 1">
      <div class="chtl-main">
        <div class="chtl-title">المرجو إدخال رقم الفلاح الخاص بوالد أو والدة المترشح </div>
        <div class="chtl-input">
          <form #f="ngForm">
            <input clrInput placeholder="AG*****" name="code" [(ngModel)]="code" required />
            <p> شرط : أن يكون إسم الفلاح المنتج مسجلاً في لائحة
              المنتجين نفس سنة حصول الإبن على الباكالوريا
            </p>
            <br />
            <div class="chtl-input-with-label">
              <div class="chtl-input-label">سنة الحصول على الباكالوريا</div>
              <div class="chtl-radio">
                <select clrSelect name="graduation_year" [(ngModel)]="graduation_year" required>
                  <option *ngFor="let option of graduation_year_options" [value]="option">{{option}}</option>
                </select>
              </div>
              <br />
            </div>
          </form>
          <div class="alert alert-danger" *ngIf="showErrorMessage">
            <div class="alert-items">
              <div class="alert-item static" role="alert">
                <div class="alert-icon-wrapper">
                  <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                </div>
                <span class="alert-text">
                  {{ errorMessage }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="chtl-btn">
          <button type="submit" (click)="sumbitForm(f)" class="btn btn-primary">التحقق من رقم الفلاح</button>
          <div class="chtl-btn">
            <a href="/"><button type="submit" class="back-btn btn btn-primary">الصفحة الرئيسية</button></a>
          </div>
        </div>
      </div>
    </div>
    <div class="chtl-second-step" *ngIf="step === 2">
      <div class="chtl-main small">
        <div class="chtl-title ">المرجو إدخال البيانات الشخصية</div>
        <div class="chtl-student-info-form">
          <form class="chtl-form" #f="ngForm">

            <div class="chtl-input-group">
              <div class="chtl-input-with-label">
                <div class="chtl-input-label">الاسم</div>
                <input type="text" placeholder="الاسم" class="clr-input" name="first_name" required
                  [(ngModel)]="student_info.first_name" />
              </div>
              <div class="chtl-input-with-label">
                <div class="chtl-input-label">النسب</div>
                <input type="text" placeholder="النسب" class="clr-input" name="last_name" required
                  [(ngModel)]="student_info.last_name" />
              </div>
            </div>
            <div class="chtl-input-group">
              <div class="chtl-input-with-label">
                <div class="chtl-input-label">تاريخ الإزدياد <span>( يجب أن لا يتجاوز عمر المترشح 18 سنة عند حصوله على
                    شهادة البكالوريا)</span> </div>
                <input type="date" max="{{date | date:'yyyy-MM-dd'}}" placeholder="تاريخ الإزدياد " name="date_of_birth"
                  class="clr-input" required [(ngModel)]="student_info.date_of_birth" />
              </div>
              <div class="chtl-input-with-label">
                <div class="chtl-input-label">عدد الإخوة</div>
                <input type="number" max="10" min="0" placeholder="عدد الإخوة" name="number_of_siblings"
                  class="clr-input" required [(ngModel)]="student_info.number_of_siblings" />
              </div>
            </div>
            <div class="chtl-input-group">
              <div class="chtl-input-with-label">
                <div class="chtl-input-label">البريد الإلكتروني</div>
                <input type="email" placeholder="البريد الإلكتروني" name="email" class="clr-input" required
                  [(ngModel)]="student_info.email" />
              </div>
              <div class="chtl-input-with-label">
                <div class="chtl-input-label">الهاتف المحمول</div>
                <input type="text" placeholder="الهاتف المحمول" name="tel" class="clr-input" required
                  [(ngModel)]="student_info.tel" />
              </div>


            </div>
            <div class="chtl-input-group">
              <div class="chtl-input-with-label">
                <div class="chtl-input-label">رقم البطاقة الوطنية للمترشح</div>
                <input type="text" placeholder="رقم البطاقة الوطنية للمترشح" name="cin" class="clr-input" required
                  [(ngModel)]="student_info.cin" />
              </div>
            </div>
            <div class="chtl-input-group">
              <input type="file" id="files" accept="application/pdf, image/png, image/jpeg, image/jpg"
                (change)="onFileSelected($event)" name="file_name" class="clr-input" style="display:none;" />
              <label [ngStyle]="{
                width: img0 ? '50%' : '100%'
              }" for="files" appDnd (fileDropped)="onFileSelected($event)">
                <cds-icon shape="upload" badge="info"></cds-icon>
                {{ student_info.file || 'صورة من البطاقة الوطنية للمترشح'}}
              </label>
              <img style="width: 250px;" *ngIf="img0" [src]="img0" alt="" srcset="" />
            </div>
            <div class="chtl-input-group">
              <div class="alert alert-danger" *ngIf="showErrorMessage">
                <div class="alert-items">
                  <div class="alert-item static" role="alert">
                    <div class="alert-icon-wrapper">
                      <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                      {{ errorMessage }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="chtl-input-group">
              <div class="chtl-btn">
                <button (click)="sumbitForm(f)" type="submit" class="btn btn-primary">التحقق من البيانات
                  الشخصية</button>
              </div>
              <div class="chtl-btn">
                <button (click)="goback()" type="submit" class="back-btn btn btn-primary">المرحلة السابقة</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="chtl-second-step" *ngIf="step === 3">
      <div class="chtl-main small">
        <div class="chtl-title ">المرجو إدخال المعطيات الدراسية</div>
        <div class="chtl-student-info-form">
          <form class="chtl-form" #f2="ngForm">
            <div class="chtl-input-group">
              <div class="chtl-input-with-label">
                <div class="chtl-input-label">شعبة البكالوريا</div>
                <clr-select-container>
                  <select clrSelect name="options" placeholder="شعبة البكالوريا" [(ngModel)]="school_info.option"
                    required>
                    <option *ngFor="let option of options" [value]="option">{{option}}</option>
                  </select>
                </clr-select-container>
                <!-- <input type="text" placeholder="شعبة البكالوريا" class="clr-input" name="last_name" required
                [(ngModel)]="student_info.last_name" /> -->
              </div>
              <div class="chtl-input-with-label">
                <div class="chtl-input-label">اسم الثانوية <span> (يجب أن تكون شهادة البكالوريا محررة من مؤسسات التعليم
                    العمومي) </span> </div>
                <input type="text" placeholder="اسم الثانوية" class="clr-input" name="first_name" required
                  [(ngModel)]="school_info.hight_school" />
              </div>

            </div>
            <div class="chtl-input-group">
              <div class="chtl-input-with-label">
                <div class="chtl-input-label"> نقطة البكالوريا<span> (يجب أن يكون المترشح حاصلا على شهادة البكالوريا
                    بميزة حسن على الأقل ) </span> </div>
                <input type="number" placeholder="نقطة البكالوريا" (change)="autoFillGrade()" min="14" max="20"
                  name="note" class="clr-input" required [(ngModel)]="school_info.note" />
              </div>

              <div class="chtl-input-with-label">
                <div class="chtl-input-label">ميزة النجاح في البكالوريا</div>
                <div class="chtl-radio">
                  <select clrSelect name="grades" [(ngModel)]="school_info.grade" required disabled>
                    <option *ngFor="let option of grades" [value]="option">{{option}}</option>
                  </select>
                </div>
                <!-- <input type="number" min="0" placeholder="ميزة النجاح في البكالوريا" name="number_of_siblings"
                class="clr-input" required [(ngModel)]="student_info.number_of_siblings" /> -->
              </div>
            </div>
            <div class="chtl-input-group">
              <input type="file" accept="application/pdf, image/png, image/jpeg, image/jpg" id="files2"
                (change)="onFileSelected($event, 1)" name="degree_file_name" class="clr-input" style="display:none;" />
              <label [ngStyle]="{
                width: img1 ? '50%' : '100%'
              }" for="files2" appDnd (fileDropped)="onFileSelected($event, 1)">
                <cds-icon shape="upload" badge="info"></cds-icon>
                {{ school_info.degree_file_name || 'صورة من شهادة البكالوريا مصادق عليها'}}
              </label>
              <img style="width: 250px;" *ngIf="img1" [src]="img1" alt="" srcset="" />
            </div>
            <div class="chtl-input-group">
              <input type="file" accept="application/pdf, image/png, image/jpeg, image/jpg" id="files3"
                (change)="onFileSelected($event, 2)" name="grades_file_name" class="clr-input" style="display:none;" />
              <label [ngStyle]="{
                width: img2 ? '50%' : '100%'
              }" for="files3" appDnd (fileDropped)="onFileSelected($event, 1)">
                <cds-icon shape="upload" badge="info"></cds-icon>
                {{ school_info.grades_file_name || 'جدول نقاط البكالوريا'}}
              </label>
              <img style="width: 250px;" *ngIf="img2" [src]="img2" alt="" srcset="" />
            </div>
            <div class="chtl-input-group">
              <div class="alert alert-danger" *ngIf="showErrorMessage">
                <div class="alert-items">
                  <div class="alert-item static" role="alert">
                    <div class="alert-icon-wrapper">
                      <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                    </div>
                    <span class="alert-text">
                      {{ errorMessage }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="chtl-input-group">
              <div class="chtl-btn">
                <button (click)="openModal = true" type="submit" class="btn btn-primary">إرسال الطلب</button>
              </div>
              <div class="chtl-btn">
                <button (click)="goback()" type="submit" class="back-btn btn btn-primary">المرحلة السابقة</button>
              </div>
            </div>
            <clr-modal [(clrModalOpen)]="openModal">
              <h3 class="modal-title">التعبير عن الموافقة </h3>
              <div class="modal-body">
                <p dir="RTL"
                  style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                  <span style='font-family:"Arial",sans-serif;'>&nbsp;</span><span
                    style='font-family:"Arial",sans-serif;'>طبقا للقانون&nbsp;</span><span dir="LTR">09</span><span
                    style='font-family:"Arial",sans-serif;'>-</span><span dir="LTR">08</span><span
                    style='font-family:"Arial",sans-serif;'>&nbsp;&deg; ، يحق لكم الولوج إلى بياناتكم الشخصية وتصحيحها و
                    الاعتراض على معالجتها.</span>
                </p>
                <p dir="RTL"
                  style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                  <span style='font-family:"Arial",sans-serif;'>&nbsp;تم الترخيص&nbsp;</span><span
                    style='font-family:"Arial",sans-serif;'>لهذه المعالجة من قبل اللجنة الوطنية لمراقبة و حماية المعطيات
                    ذات الطابع الشخصي &nbsp; تحت الرقم&nbsp;</span>
                </p>
                <p dir="RTL"
                  style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                  <span dir="LTR">&nbsp;</span>
                </p>
                <p dir="RTL"
                  style='margin-top:0cm;margin-right:35.4pt;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'>
                  <span style='font-family:"Arial",sans-serif;'>لقد قرأت وقبلت الشروط العامة للاستخدام ، ولا سيما
                    الإشعار المتعلق بحماية البيانات الشخصية.&nbsp;</span>
                </p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline" (click)="openModal = false">لا أوافق</button>
                <button type="button" class="btn btn-primary" type="submit" (click)="sumbitForm(f2)">أوافق</button>
              </div>
            </clr-modal>
          </form>
        </div>
      </div>
    </div>

    <div class="chtl-forth-step" *ngIf="step === 4">
      <cds-icon shape="check-circle" solid></cds-icon>
      <div class="chtl-strong">
        لقد تم تسجيل طلبكم بنجاح
      </div>
      <div class="chtl-input-group">
        <div class="chtl-btn">
          <a href="/"><button style="background-color: #17479e !important;" type="submit"
              class="back-btn btn btn-primary">الصفحة الرئيسية</button></a>
        </div>
      </div>
    </div>
  </div>


  <div class="chtl-loading" *ngIf="loadingVisible">
    <svg>
      <circle cx="50" cy="50" r="40" stroke="#3174ba" stroke-dasharray="78.5 235.5" stroke-width="3" fill="none" />
      <circle cx="50" cy="50" r="30" stroke="#fed500" stroke-dasharray="62.8 188.8" stroke-width="3" fill="none" />
      <circle cx="50" cy="50" r="20" stroke="#009fe3" stroke-dasharray="47.1 141.3" stroke-width="3" fill="none" />
    </svg>
  </div>


  <div class="chtl-footer">

    <app-footer></app-footer>
  </div>


</div>
